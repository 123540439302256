import { graphql } from 'gatsby'
import React from 'react'

import { capitalize } from '../../lib/string'

import Breadcrumb from '../components/breadcrumb'
import FragrancesList from '../components/fragrances-list'
import Heading from '../components/heading'
import Image from '../components/image'
import Layout from '../components/layout'
import Link from '../components/link'
import Paragraph from '../components/paragraph'
import SEO from '../components/seo'


export const query = graphql`
  query NoteTemplateQuery(
    $genderId: PostGraphile_Gender!,
    $imageMain: String!,
    $noteId: String!,
  ) {
    ...SiteInfo
    imageMain: file(relativePath: { eq: $imageMain }) { ...ImageMain }
    results: postgres {
      note: noteById(id: $noteId) {
        ...Note
        accord: accordByAccord {
          ...Accord
          family: familyByFamily {
            ...Family
          }
        }
        layer {
          ...Layer
        }
        fragrances: fragrancesList(filter: { gender: { equalTo: $genderId }}) {
          ...Fragrance
        }
      }
    }
  }
`

export default ({ data }) => {
  const { links, meta } = data.site.siteMetadata
  const { entity } = meta
  const { note } = data.results
  const { fragrances } = note
  const imageMain = data.imageMain && data.imageMain.childImageSharp.fixed

  const page = note.name
  const title = `${note.name} ${entity.singular} Note`
  const seoTitle = `${title} - ${note.accord.name} Accord`
  const keywords = title.split(/\W+/)
  const trail = [
    {
      name: 'Notes',
      slug: links.notes,
    },
    {
      name: note.accord.family.name,
      slug: note.accord.family.slug,
    },
    {
      name: capitalize(note.accord.id),
      slug: note.accord.slug,
    },
    {
      name: page,
      slug: note.slug,
    },
  ]
  const schema = {
    breadcrumb: trail,
    description: note.about,
    image: imageMain && imageMain.src,
    keywords,
    name: seoTitle,
    thing: note,
    slug: note.slug,
  }

  return (
    <Layout>
      <SEO
        description={note.about}
        keywords={keywords}
        schema={schema}
        title={seoTitle}
      />
      <Breadcrumb trail={trail} />

      <Heading>{title}</Heading>

      {imageMain && (
        <Image
          alt={note.name}
          credit={note.image}
          fixed={imageMain}
        />
      )}

      {note.about && (
        <Paragraph>
          {note.about}
        </Paragraph>
      )}

      <Paragraph>
        Family: {' '}
        <Link href={note.accord.family.slug}>
          {note.accord.family.name}
        </Link>
      </Paragraph>

      <Paragraph>
        Accord: {' '}
        <Link href={note.accord.slug}>
          {note.accord.name}
        </Link>
      </Paragraph>

      <Paragraph>
        {note.name} is most often a {' '}
        <Link href={note.layer.slug}>
          {note.layer.name} Note
        </Link> {' '}
        on the {' '}
        <Link href={links.layers}>
          Fragrance Pyramid
        </Link>.
      </Paragraph>

      {(fragrances.length > 0) && (
        <>
          <Heading level={2}>
            {note.name} {entity.plural}
          </Heading>
          <FragrancesList fragrances={fragrances} />
        </>
      )}

      <Breadcrumb trail={trail} />
    </Layout>
  )
}
